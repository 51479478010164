<template>
  <div class="section-wrapper">
    <div class="breadcrumb-wrapper">
      <b-breadcrumb class="custom-bread"></b-breadcrumb>
    </div>
      <div class="form-wrapper">
      <b-card title="Transaction Search">
          <b-card-text>
              <b-row style="font-size: 13px;">
                <b-col sm="12" md="3">
                  <b-form-group
                      id="name"
                      label="Name"
                      label-for="name"
                  >
                      <b-form-input
                      id="name"
                      v-model="search.name"
                      type="text"
                      placeholder="Enter Name"
                      required
                      ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="3">
                  <b-form-group
                      id="email"
                      label="Email"
                      label-for="email"
                  >
                      <b-form-input
                      id="name"
                      v-model="search.email"
                      type="email"
                      placeholder="Enter email"
                      required
                      ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="3">
                  <b-form-group
                      id="mobile"
                      label="Mobile"
                      label-for="mobile"
                  >
                      <b-form-input
                      id="mobile"
                      v-model="search.mobile"
                      type="mobile"
                      placeholder="Enter mobile"
                      required
                      ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="3">
                  <b-form-group
                      id="address"
                      label="Address"
                      label-for="address"
                  >
                      <b-form-input
                      id="address"
                      v-model="search.address"
                      type="address"
                      placeholder="Enter Address"
                      required
                      ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="3">
                  <b-form-group
                    id="date"
                    label="Date Range"
                    label-for="date"
                    >
                    <flat-pickr
                      id="date"
                      :config="flatPickrConfig"
                      v-model="search.date"
                      class="form-control"
                      placeholder="Enter Date"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="3">
                  <br>
                  <b-button size="sm" variant="primary"><i class="ri-search-line"></i> Search</b-button>
                </b-col>
              </b-row>
          </b-card-text>
      </b-card>
  </div>
  <b-card class="mt-3" title="Transaction List">
    <b-row>
      <b-col>
        <div class="table-wrapper table-responsive mt-0">
          <table class="table table-striped table-hover table-bordered">
            <thead>
              <tr  style="font-size: 13px;">
                <th scope="col" class="text-center">SL</th>
                <th scope="col" class="text-center">Date</th>
                <th scope="col" class="text-center">Client Info</th>
                <th scope="col" class="text-center">Payment Status</th>
                <th scope="col" class="text-center">Status</th>
                <th scope="col" class="text-center">Amount</th>
                <th scope="col" class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr style="font-size: 12px;">
                <td class="text-center">1</td>
                <td class="text-left">10-08-2023</td>
                <td class="text-left">Mr.Karim Mia <br> karim@gmail.com <br> 01754512541 <br> Dhaka, Uttara</td>
                <td class="text-center">Approved</td>
                <td class="text-center"><span class="badge badge-pill badge-info">Active</span></td>
                <td class="text-right">45000</td>
                <td class="text-center">
                  <a href="javascript:" class="action-btn edit"><i class="ri-pencil-fill"></i></a>
                  <a href="javascript:" class="action-btn status"><i class="ri-eye-fill"></i></a>
                  <a href="javascript:" class="action-btn active"><i class="ri-check-fill"></i></a>
                </td>
              </tr>
              <tr style="font-size: 12px;">
                <td class="text-center">2</td>
                <td class="text-left">10-08-2023</td>
                <td class="text-left">Mr.Karim Mia <br> karim@gmail.com <br> 01754512541 <br> Dhaka, Uttara</td>
                <td class="text-center">Approved</td>
                <td class="text-center"><span class="badge badge-pill badge-info">Active</span></td>
                <td class="text-right">45000</td>
                <td class="text-center">
                  <a href="javascript:" class="action-btn edit"><i class="ri-pencil-fill"></i></a>
                  <a href="javascript:" class="action-btn status"><i class="ri-eye-fill"></i></a>
                  <a href="javascript:" class="action-btn active"><i class="ri-check-fill"></i></a>
                </td>
              </tr>
              <tr style="font-size: 12px;">
                <td class="text-center">3</td>
                <td class="text-left">10-08-2023</td>
                <td class="text-left">Mr.Karim Mia <br> karim@gmail.com <br> 01754512541 <br> Dhaka, Uttara</td>
                <td class="text-center">Approved</td>
                <td class="text-center"><span class="badge badge-pill badge-info">Active</span></td>
                <td class="text-right">45000</td>
                <td class="text-center">
                  <a href="javascript:" class="action-btn edit"><i class="ri-pencil-fill"></i></a>
                  <a href="javascript:" class="action-btn status"><i class="ri-eye-fill"></i></a>
                  <a href="javascript:" class="action-btn active"><i class="ri-check-fill"></i></a>
                </td>
              </tr>
              <tr style="font-size: 12px;">
                <td class="text-center">4</td>
                <td class="text-left">10-08-2023</td>
                <td class="text-left">Mr.Karim Mia <br> karim@gmail.com <br> 01754512541 <br> Dhaka, Uttara</td>
                <td class="text-center">Approved</td>
                <td class="text-center"><span class="badge badge-pill badge-info">Active</span></td>
                <td class="text-right">45000</td>
                <td class="text-center">
                  <a href="javascript:" class="action-btn edit"><i class="ri-pencil-fill"></i></a>
                  <a href="javascript:" class="action-btn status"><i class="ri-eye-fill"></i></a>
                  <a href="javascript:" class="action-btn active"><i class="ri-check-fill"></i></a>
                </td>
              </tr>
              <tr style="font-size: 12px;">
                <td class="text-center">5</td>
                <td class="text-left">10-08-2023</td>
                <td class="text-left">Mr.Karim Mia <br> karim@gmail.com <br> 01754512541 <br> Dhaka, Uttara</td>
                <td class="text-center">Approved</td>
                <td class="text-center"><span class="badge badge-pill badge-info">Active</span></td>
                <td class="text-right">45000</td>
                <td class="text-center">
                  <a href="javascript:" class="action-btn edit"><i class="ri-pencil-fill"></i></a>
                  <a href="javascript:" class="action-btn status"><i class="ri-eye-fill"></i></a>
                  <a href="javascript:" class="action-btn active"><i class="ri-check-fill"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </b-card>
    <div class="pagination-wrapper mt-4">
      <span>Showing 5 from 30 entries</span>
      <b-pagination v-model="currentPage" :total-rows="rows" size="sm"></b-pagination>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
export default {
  components: {
    flatPickr
  },
  data () {
    return {
      // pagination
      rows: 100,
      currentPage: 1,
      // form data
      search: {
        date: '',
        start_date: '',
        end_date: ''
      },
      ranges: [{ text: '5', value: null }, '5', '10', '15', '20'],
      value: '',
      // table data
      sortBy: 'user',
      sortDesc: false,
      flatPickrConfig: {
        mode: 'range',
        maxDate: 'today',
        dateFormat: 'Y-m-d'
      }
    }
  },
  mounted () {
    this.flatpickr('#date', {
      mode: 'range',
      minDate: 'today',
      dateFormat: 'Y-m-d',
      disable: [
        function (date) {
          // disable every multiple of 8
          return !(date.getDate() % 8)
        }
      ]
    })
  },
  methods: {
    deleteConfirmation () {
      this.$swal({
        title: 'Are you sure to delete ?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          // declare confirmed method to hit api
          this.approveToastr()
        }
      })
    },
    approveToastr () {
      this.$toast.success({
        title: 'Success',
        message: 'Data deleted successfully'
      })
    }
  }
}
</script>
